import React from "react"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"


class JobsPage extends React.Component {
    render() {
        const page =  this.props.data.allMarkdownRemark.edges[0].node;
        return (<Layout>
          <SEO title="karriere" />
          <section id="karriere" className="page-section">
            <article dangerouslySetInnerHTML={{ __html: page.html }} />
          </section>
        </Layout>)
      }
}

export default JobsPage

export const pageQuery = graphql`
{
  allMarkdownRemark(filter: { frontmatter:  { templateId: { eq:"karriere"}}}){
   edges{
    node{
      html,
      frontmatter {
        title
      }
    }
  }
  }
}
`;